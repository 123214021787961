//body {
//	margin-top: 316px;
//}

//header {
//	position: fixed;
//	z-index: 999999;
//	width: 100%;
//	left: 0;
//	top: 0;
//}

.header {
	padding: 1.5625rem 0 0 0;
	background-color: white;
	position: static;
	width: 100%;
	top: 3.12rem;

	.button-menu {
		justify-content: flex-end;
		margin-top: 2rem;
		flex-wrap: wrap;
		display: flex;

		.btn {
			margin: 0 0.9375rem;
			width: 100%;
		}

		ul.social-icons {
			align-items: center;
			list-style: none;
			display: flex;
			padding: 0;
			margin: 0;

			li {
				font-size: 1.5625rem;
				margin: 0 0.625rem;
				padding: 0;

				a {
					color: $purple;

					&:hover {
						opacity: 0.75;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	.burger-menu-container {
		background-color: $purple;
		color: white;

		button.navbar-toggler {
			font-family: $font-family-avenir;
			text-transform: uppercase;
			font-weight: 300;
			color: white;
			width: 100%;
			font-size: 1.25rem;
			line-height: 1.85rem;
		}
	}

	.menu-container {
		background-color: $light-grey;
		padding-right: 0;
		padding-left: 0;

		.navbar {
			margin-top: 0;
			padding: 0;

			.top {
				background-color: $pink;

				ul.navbar-nav li.nav-item {
					a.nav-link {
						color: white;
						border-top: none;
					}

					&:last-of-type {
						a.nav-link {
							border-bottom: none;
						}
					}
				}
			}

			ul.navbar-nav {
				flex-wrap: wrap;

				li.nav-item {

					a.nav-link {
						font-family: $font-family-avenir;
						letter-spacing: 0.4px;
						padding: 0.75rem 1.5625rem; // 0 1.5625rem;
						font-weight: 900;
						font-size: 1.175rem; //1rem;
						color: black;
						border-top: 1px solid white;

						&.dropdown-toggle::after {
							display: none;
						}

						&:hover {
							color: $purple;
						}
					}

					&:last-of-type {
						a.nav-link {
							padding-right: 0;
							border-bottom: 1px solid white;
						}
					}

					&.dropdown {

						&.show {
							a.nav-link::after {
								content: "\f068";
							}
						}

						a.nav-link {
							position: relative;

							&::after {
								content: "\f067";
								font-family: 'Font Awesome 5 Pro';
								display: block;
								position: absolute;
								top: 50%;
								right: 20px;
								transform: translateY(-50%);
								border: none;
							}
						}
					}

					.dropdown-menu {
						border: none;
						box-shadow: none;
						background-color: $light-grey;
						padding-left: 1.5rem;

						a {
							font-family: $font-family-avenir;
							background-color: $light-grey;
							letter-spacing: 0.4px;
							margin: 0 0 0.4rem 0;
							font-weight: 500;
							font-size: 1rem;
							color: $black;

							&:hover {
								color: $purple;
							}

							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}

					&.dropdown.show a.nav-link {
						color: $purple;
					}
				}
			}
		}
	}

}

@media only screen and (min-width: 768px) {
	.header {
		.button-menu {
			.btn {
				width: auto;
			}
		}
	}
}

@media only screen and (min-width: 1200px) {//1440
	.header {
		padding-bottom: 1.5625rem;

		.menu-container {
			background-color: white;
			padding-right: 15px;
			padding-left: 15px;

			.navbar {
				margin-top: 0;//1.15rem;//2.4rem
				padding: 0.5rem 0;

				ul.navbar-nav {
					margin-top: 1rem;

					li.nav-item {

						a.nav-link {
							padding: 0.75rem 1rem;
							font-size: 1rem;
						}

						.dropdown-menu {
							border-radius: 0;
							border-left: none;
							border-right: none;
							border-bottom: none;
							border-top: 2px solid $purple;
							padding: 0.875rem 0;
							margin: 1.25rem 0 0 0;
							box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
							background-color: white;

							a {
								background-color: white;
							}
						}

						&.dropdown {
							a.nav-link::after {
								display: none;
							}
						}

					}
				}
			}
		}
	}
}

@media screen and (min-width: 1440px) {
	.header .menu-container .navbar {
		margin-top: 1.15rem;
	}
}

@media screen and (min-width: 1600px) {
	.header .menu-container .navbar {
		margin-top: 1.8rem;
	}
}

//@media only screen and (min-width:769px) {
//    .dropdown:hover .dropdown-menu {
//        display: block;
//    }
//    .dropdown-submenu {
//        position: relative !important;
//    }
//    .dropdown-submenu>.dropdown-menu {
//        top: 0 !important;
//        left: 100% !important;
//        margin-top: -6px !important;
//        margin-left: -1px !important;
//        border-radius: 0 !important;
//    }
//    .dropdown-submenu:hover>.dropdown-menu {
//        display: block !important;
//    }
//    .dropdown-submenu>a:after {
//        display: block;
//        content: "\f105";
//        font-family: 'FontAwesome';
//        margin-top: -18px;
//        right: 15px;
//        position: absolute;
//        font-weight: 300;
//    }
//}