.property-single, .block-management.case-study {
	span.price {
		font-family: $font-family-avenir;
		line-height: 2.1875rem;
		letter-spacing: 0.4px;
		font-size: 1.5625rem;
		color: $black;
	}

	div.images {
		.image-container {
			img.property-image {
				max-width: 100%;
				height: auto;
				width: 100%;
			}
		}

		.tns-outer {
			cursor: pointer;
		}

		.underbox {
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 1.5rem;

			.branch {
				position: relative;
				top: -4px;

				span {
					font-family: $font-family-avenir;
					letter-spacing: 0.4px;
					font-size: 1.25rem;
					font-weight: 900;
					position: relative;
					margin-left: 10px;
					color: $black;
					top: 5px;
				}
			}
			.slider-controls {
				font-family: $font-family-avenir;
				justify-content: space-evenly;
				font-weight: bold;
				display: flex;
				color: black;
				font-size: 1.25rem;

				button {
					background-color: transparent;
					outline: 0;
					border: 0;
					color: $orange;
					font-size: 2rem;
				}

				.slideshow-number {
					align-self: center;
				}
			}
			.buttons {
			}
		}

		.next-pictures {
			.slider-thumbnail-1 {
				margin-bottom: 31px;
			}

			.slider-thumbnail-2 {
				position: relative;

				.image-count {
					font-family: $font-family-avenir;
					transform: translateY(-50%);
					text-align: center;
					position: absolute;
					line-height: 1;
					font-weight: 900;
					font-size: 2rem;
					color: white;
					z-index: 999;
					width: 100%;
					top: 50%;
					left: 0;
				}

				&::after {
					content: " ";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.5);
				}
			}
		}
	}

	.map-container {
		#map {
			min-height: 600px;
		}
	}

}

#fancyMap, #fancyStreetView {
	width: 100%;
	max-width: 80%;
}