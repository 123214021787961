.our-branches {
	.branch-item {

		a.inner {
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
			margin-bottom: 2.5rem;
			padding: 1.75rem;
			display: block;

			div.images {
				display: flex;
				flex-wrap: nowrap;

				div.large {
					max-width: 100%;
					flex-basis: 65%;

					img {
						width: 100%;
					}
				}

				div.small {
					flex-basis: 35%;
					margin-left: 5px;
					overflow: hidden;

					div {
						display: flex;
						flex-grow: 1;
						height: 50%;
						width: 100%;
						margin: 5px 0;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;

						&:first-of-type {
							margin-top: 0;
						}

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}

			div.name {
				margin-top: 1rem;
				display: flex;
				align-content: center;

				img {
					display: flex;
					align-self: center;
				}

				span {
					font-family: $font-family-avenir;
					position: relative;
					margin-left: 10px;
					font-weight: 900;
					font-size: 1.25rem;
					color: $black;
					top: 4px;
				}
			}

			&:hover {
				text-decoration: none;
			}

		}

	}
}

.show-branch {
	.contact-details {
		.item {
			margin-bottom: 0.75rem;
			align-items: center;
			display: flex;

			&:hover {
				text-decoration: none;
			}

			.icon {
				font-size: 1.75rem;
				color: $yellow;
			}

			.detail {
				font-family: $font-family-avenir;
				font-size: 1.125rem;
				margin-left: 1rem;
				font-weight: 900;
				color: $black;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.branch-map {
		min-height: 400px;
	}

	.area-guide {
		.content {
			h5 {
				margin-bottom: 0rem;

				&:first-of-type {
					margin-top: 2rem;
				}
			}

			ul {
				margin-bottom: 2rem;

				li {
					font-weight: 900;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			a {
				color: $purple;
			}
		}
	}

	.team-member {
		.inner {
			padding: 1.875rem 1.5rem 1rem 1.5rem;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
			text-align: center;

			img {
				border-radius: 50%;
				max-width: 250px;
				width: 100%;
			}

			span {
				font-family: $font-family-avenir;
				line-height: 1.875rem;
				letter-spacing: 0.4px;
				font-size: 1.25rem;
				font-weight: 400;
				display: block;
				color: $black;

				&.name {
					font-weight: 900;
					margin-top: 1.875rem;
				}

				&.position {
					margin-bottom: 1.875rem;
				}
			}

			hr {
				width: 80%;
			}
		}

	}
}
