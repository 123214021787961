.page-background {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	img {
		transform: translate(-50%,-50%);
		position: absolute;
		height: auto;
		width: 50%;
		top: 50%;
		left: 85%;
	}
}

.page-background-image {
	max-width: 400px;
	margin: -80px auto 0 auto;
	display: block;
	position: relative;
	top: 80px;
	z-index: -1;
	opacity: 0.35;
	width:100%;
}

@media screen and (min-width: 1200px) {
	.page-background {
		display: block;
	}
	.page-background-image {
		display: none;
	}
}