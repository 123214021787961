.footer-container {
	background-color: white;
	margin-top: 9rem;

	.footer:not(ul) {
		background-color: rgba(#ABB4C2, 0.10);

		.footer-top {
			padding: 1.5625rem 0;

			.footer-logo {
				width: 100%;
				max-width: 180px;
				height: auto;
				align-self: flex-start;
			}

			.newsletter-sign-up {
				flex-grow: 1;

				.input-group {
					.form-control {
						border-radius: 0;
						padding: 5px 15px;
						height: auto;
						border: none;
					}

					.input-group-append {
						button {
							padding: 0.5rem 3.5rem;
							margin: 0;
						}
					}
				}
			}
		}


		div.footer-bottom {
			background-color: $purple;

			ul.footer {
				flex-direction: row;
				list-style: none;
				flex-wrap: wrap;
				display: flex;
				padding: 0;
				margin: 0;

				li.footer-item {
					font-family: $font-family-avenir;
					font-size: 1rem;
					font-weight: 900;
					padding: 0.6rem 1rem 0.5rem;
					color: white;

					ul.social-icons {
						font-size: 1.125rem;
						align-items: center;
						list-style: none;
						display: flex;
						padding: 0;
						margin: 0;

						li {
							padding: 0 0.5rem;

							&:first-of-type {
								padding-left: 0;
							}

							a {
								color: white;
							}
						}
					}

					a.footer-link {
						color: white;

						&:hover {
							color: white;
						}
					}

					&:first-of-type {
						padding-left: 0;
					}

					&:last-of-type {
						padding-right: 0;
					}
				}
			}
		}
	}

}

@media screen and (min-width: 991px) {
	.footer-container {
		.footer:not(ul) div.footer-bottom ul.footer li.footer-item {
			padding: 0.6rem 0.5rem 0.5rem;
		}
	}
}

@media screen and (min-width: 1024px) {
	.footer-container {
		.footer:not(ul) div.footer-bottom ul.footer li.footer-item {
			font-size: 0.75rem;
		}
	}
}

@media screen and (min-width: 1200px) {
	.footer-container {
		.footer:not(ul) div.footer-bottom ul.footer li.footer-item {
			font-size: 0.9rem;
		}
	}
}

@media screen and (min-width: 1366px) {
	.footer-container {
		.footer:not(ul) div.footer-bottom ul.footer li.footer-item {
			font-size: 1rem;
		}
	}
}