.meet-the-team {
	.search-container {
		.branches {
			text-align: center;

			span {
				padding: 0.5rem 0;
				font-size: 1.25rem;
				color: white;
				font-weight: 600;
				font-family: $font-family-avenir;
				display: block;
			}
		}
	}

	.search-input-wrapper {
		display: flex;
		flex-grow: 1;
		height: auto;
		position: relative;

		input {
			height: 100%;
		}

		&::after {
			font-family: 'Font Awesome 5 Pro';
			transform: translateY(-50%);
			position: absolute;
			content: "\f002";
			display: block;
			right: 1rem;
			z-index: 50;
			top: 50%;
			font-size: 1.25rem;
			font-weight: bold;
			color: $purple;
			pointer-events: none;
		}
	}

	.team-container {
		.team-member-inner {
			display: flex;
			flex-direction: column;
			padding: 1.875rem 0 1rem 0;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
			text-align: center;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			img {
				border-radius: 50%;
				max-width: 300px;
				padding: 0 2rem;
				height: auto;
				width: 100%;
			}

			span {
				font-family: $font-family-avenir;
				line-height: 1.875rem;
				letter-spacing: 0.4px;
				font-size: 1.25rem;
				font-weight: 400;
				display: block;
				color: $black;

				&.name {
					font-weight: 900;
					margin-top: 1.875rem;
				}

				&.position {
					margin-bottom: 1.875rem;
				}
			}

			hr {
				width: 80%;
			}
		}
	}
}

#teamMemberModal {

	img {
		width: 100%;
		height: auto;
		max-width: 300px;
		border-radius: 50%;
		display: flex;
	}
	span {
		font-family: $font-family-avenir;
		letter-spacing: 0.4px;
		font-size: 1.25rem;
		font-weight: 900;
		display: flex;
		color: $black;

		&.branch {
			color: $yellow;
		}

		&.position {
			font-weight: 400;
		}
	}

	.contact-options {
		.line {
			display: flex;
			flex-direction: row;
			justify-content: space-around;

			a {
				font-family: $font-family-avenir;
				letter-spacing: 0.4px;
				font-size: 1.125rem;
				font-weight: 900;
				color: $black;
				line-height: 1;

				i {
					font-size: 1.25rem;
					margin-right: 1rem;
					color: $yellow;
					position: relative;
					top: 1px;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}