.main-content.block-management {

	.case-studies {
		.case-study-item {
			.inner {
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
				flex-direction: column;
				display: flex;
				height: 100%;
				color: $black;
				opacity: 1;
				transition: opacity 175ms ease-out;

				.information {
					font-family: $font-family-avenir;
					font-size: 1.125rem;
					padding: 1.5rem 1.8rem;

					.title {
						font-weight: 900;
						font-size: 1.25rem;
					}

					p {
						margin: 1rem 0 0 0;
						padding-bottom: 0;
					}
				}

				&:hover {
					text-decoration: none;
					opacity: 0.75;
					color: $black;
				}
			}
		}
	}

}