.mt-6 { margin-top: 5rem; }
.mb-6 { margin-bottom: 5rem; }
.pt-6 {padding-top:5rem;}
.pb-6 {padding-bottom:5rem;}
.py-6 {padding-top:5rem;padding-bottom:5rem;}

.mt-7 { margin-top: 7rem; }
.mb-7 { margin-bottom: 7rem; }
.pt-7 {padding-top:7rem;}
.pb-7 {padding-bottom:7rem;}
.py-7 {padding-top:7rem;padding-bottom:7rem;}

.mt-8 { margin-top: 9rem; }
.mb-8 { margin-bottom: 9rem; }
.pt-8 {padding-top:9rem;}
.pb-8 {padding-bottom:9rem;}

.bg-yellow { background-color: $yellow; }
.bg-red { background-color: $red; }
.bg-purple { background-color: $purple; }
.bg-light-purple { background-color: $light-purple; }
.bg-cyan { background-color: $cyan; }

.text-cyan { color: $cyan !important; }
.text-yellow { color: $yellow !important; }

.tighten-footer {margin-bottom:-9rem;}

.font-family-avenir { font-family: $font-family-avenir; }

.shadow { box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16); }

@media screen and (min-width: 1440px) {
	.mt-xxl-8 { margin-top: 8rem !important; }
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}