div.main-content.lifeAtHomeArchive {
	.issue-item {
		.issue-inner {
			transition: opacity 250ms ease-out;
			display: block;
			opacity: 1;
			color: black;

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}

			img {
				margin-bottom: 1.56rem;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
			}

			span {
				font-family: $font-family-avenir;
				text-align: center;
				font-size: 1.125rem;
				letter-spacing: 0.4px;
				display: block;

				&.issue-title {
					font-size: 1.5625rem;
					margin-bottom: 1rem;
					font-weight: 600;
				}
			}
		}
	}
}