.news-page, .latest-news-sidebar {
	.news-items, .latest-news-sidebar-container {
		.news-item, .latest-news-sidebar-item {
			margin-bottom: 30px;

			&:nth-child(4n+2) .inner .top span.date {
				background-color: $light-purple;
			}

			&:nth-child(4n+3) .inner .top span.date {
				background-color: $purple;
			}

			&:nth-child(4n+4) .inner .top span.date {
				background-color: $red;
			}

			.inner {
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
				transition: opacity 200ms ease-out;
				flex-direction: column;
				height: 100%;
				display: flex;
				opacity: 1;

				.top {
					position: relative;

					span.date {
						font-family: $font-family-avenir;
						padding: 0.5rem 1.5625rem;
						background-color: $yellow;
						position: absolute;
						font-weight: 900;
						font-size: 1rem;
						color: white;
						bottom: 0;
						left: 0;
					}
				}

				.bottom {
					justify-content: space-between;
					flex-direction: column;
					padding: 1.5625rem;
					display: flex;
					height: 100%;

					span {
						font-family: $font-family-avenir;
						display: block;
						color: $black;
						width: 100%;

						&.heading {
							line-height: 1.875rem;
							font-size: 1.5625rem;
							font-weight: 900;
							margin-bottom: 1.3rem;
						}
					}
				}

				&:hover {
					text-decoration: none;
					opacity: 0.8;
				}
			}
		}

		.latest-news-sidebar-item {
			.inner {
				.top span.date {
					padding: 0.5rem 1.2rem;
					font-size: 0.9rem;
				}
				.bottom {
					padding: 1.2rem;

					span.heading {
						font-size: 1.25rem;
						line-height: 1.5625rem;
						margin-bottom: 0.8rem;
					}
				}
			}
		}
	}
}

.latest-news-sidebar {
	margin-top: 2rem;

	.latest-news-sidebar-container .latest-news-sidebar-item .inner {
		margin-bottom: 15px;
	}

	span.heading {
		font-family: $font-family-avenir;
		line-height: 1.875rem;
		font-size: 1.5625rem;
		font-weight: 900;
		display: block;
		margin-bottom: 1rem;
		color: $black;
	}
}

.news-single {
	.news-item {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
		padding: 3.125rem;

		div.image {
			position: relative;
			margin-bottom: 2rem;

			span.date {
				font-family: $font-family-avenir;
				padding: 0.5rem 1.5625rem;
				background-color: $yellow;
				position: absolute;
				font-weight: 900;
				font-size: 1rem;
				color: white;
				bottom: 0;
				left: 0;
			}
		}

		div.content-container p:last-child {
			margin-bottom: 0;
		}
	}
}
