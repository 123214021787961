.form-control, .custom-file .custom-file-label {
	border-color: $black;
	min-height: 46px;
	border-radius: 0;

	&:disabled {
		background-color: white;
	}
}

label {
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 600;
	font-family: $font-family-avenir;
}

.bootstrap-select:not([class*="col-"]):not(.input-group-btn) {
	width: 100%;
	height: 100%;
	border: 1px solid black;


	.btn.dropdown-toggle {
		height: 100%;
		padding: 0.375rem 0.75rem;
		background-color: white;
		border-radius: 0;
		font-weight: 500;
		font-size: 0.9rem;
		width: 100%;

		&.bs-placeholder {
			color: black;
		}

		.filter-option {
			.filter-option-inner {
				align-items: center;
				display: flex;
				height: 100%;
			}
		}

		&:hover {
			background-color: rgba($light-grey, 0.15);
		}
	}

	&.picker-light-purple .dropdown-menu li a.dropdown-item.active, .dropdown-menu li a.dropdown-item.active:active {
		background-color: $light-purple;
	}

	&.picker-yellow .dropdown-menu li a.dropdown-item.active, .dropdown-menu li a.dropdown-item.active:active {
		background-color: $yellow;
	}

	&.picker-orange .dropdown-menu li a.dropdown-item.active, .dropdown-menu li a.dropdown-item.active:active {
		background-color: $orange;
	}

	&.picker-red .dropdown-menu li a.dropdown-item.active, .dropdown-menu li a.dropdown-item.active:active {
		background-color: $red;
	}

	&.picker-purple .dropdown-menu li a.dropdown-item.active, .dropdown-menu li a.dropdown-item.active:active {
		background-color: $purple;
	}

	.dropdown-menu {
		li {
			font-size: 1rem;
			a {
				&.dropdown-item.active, &:active {
					background-color: $orange;
					color: white;
				}
			}
		}
	}
}

.custom-file {
	height: 100%;

	.custom-file-input {
		&:lang(en) ~ .custom-file-label::after {
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
			content: "\f093";
			font-size: 1.2rem;
			color: $yellow;
		}
	}

	.custom-file-label {
		align-items: center;
		display: flex;

		&::after {
			background-color: white;
			align-items: center;
			border-left: none;
			display: flex;
			height: 100%;
		}
	}
}

.custom-control.custom-checkbox.custom-control-inline {
	padding-left: 2rem;

	label.custom-control-label {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		/* Non-prefixed version, currently
										 supported by Chrome, Edge, Opera and Firefox */

		&::before, &::after {
			top: -1px;
			left: -2rem;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 0px;
			border-color: $black;
		}

		&::before {

		}

		&::after {
			background: no-repeat 100%/100% 65%;
		}
	}

	input.custom-control-input {
		&:focus {
			~ .custom-control-label {
				&::before {
					box-shadow: none;
				}
			}
		}

		&:checked {
			~ .custom-control-label {
				&::before {
					background-color: white;
					border-color: $black;
				}

				&::after {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2300ADDA' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
				}
			}
		}
	}
}