[data-a11y-dialog-native] .dialog-overlay {
	display: none;
}

dialog[open] {
	display: block;
}

.dialog {

	[aria-hidden="true"] {
		display: none;
	}

	&:not([aria-hidden="true"]) {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 999999999;
		width: 100%;
		height: 100%;
		overflow-x:hidden;
		overflow-y: auto;
		-webkit-backface-visibility: hidden;
		background: rgba(0,0,0, 0.75);
	}

	.dialog-content-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin-bottom: 30px;

		&:before {
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			content: '';
		}

		.dialog-content {
			position: absolute;
			width: calc(100% - 50px);
			max-width: 95%;
			top: 30px;
			transform: translate(-50%, 0%);
			display: inline-block;

			.modal__content {
				padding: 40px;
			}

			&::after {
				content: '';
				display: block;
				height: 30px;
				width: 100%;
				background-color: transparent;
			}
		}

		@media screen and (min-width: 768px) and (min-height: 768px) {
			.dialog-content {
				max-width: 80%;
				top: 50%;
				transform: translate(-50%, -50%);

				&::after {
					display: none;
				}
			}
		}
		@media screen and (min-width: 1400px) and (min-height: 768px) {
			.dialog-content {
				max-width: 75%;
			}
		}
	}


	.dialog-overlay {
		display: none;
	}
}

.dialog[aria-hidden="true"] {
	display: none;
}

.dialog-content {
	.modal__content {
		background-color: rgb(255, 255, 255);
	}
	z-index: 3;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	//margin: 0;
	//width: 100%;
	//border: none;
	//max-width: 80%;
	//padding: 2rem;
	//height: 100%;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.dialog:not([aria-hidden='true']) > .dialog-overlay {
	animation: fade-in 200ms 1 both;
}

.dialog:not([aria-hidden='true']) > .dialog-content {
	animation: appear 400ms 150ms 1 both;
}

.dialog-overlay {
	background-color: rgba(43, 46, 56, 0.9);
}

.dialog-close {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	border: 0;
	padding: 0;
	background-color: transparent;
	font-weight: bold;
	font-size: 1.5rem;
	width: 2rem;
	text-align: center;
	cursor: pointer;
	z-index: 999;
	color: $yellow;
	outline: none;
	&:focus {
		outline: none;
	}
}