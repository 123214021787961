.btn {
    font-family: $font-family-avenir;
    //padding: 0.5rem 1.5625rem;
    padding: 0.5rem 1.5rem;
    letter-spacing: 0.4px;
    font-size: 1rem;
    margin: 0;
    font-weight: 900;
    border-radius: 0;
    transition: background-color 175ms ease-out, color 175ms ease-out;
    width: 100%;
    z-index: 5001; /* prevents snow falling over headings */

    &.btn-shadow {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
        padding: 0.4rem 1.3rem;
        display: inline-block;
        //margin-right: 1rem;
        font-size: 1.125rem;
        font-weight: 400;

        i {
            margin-right: 0.5rem;
            position: relative;
            font-size: 1.5rem;
            color: $purple;
            top: 2px;
        }

        &:hover {
            background-color: $purple;
            color: white;

            i {
                color: white;
            }
        }
    }

    &.btn-rounded {
        border-radius: 50px !important;
    }

    &.btn-yellow {
        background-color: $yellow !important;
        color: white !important;
    }

    &.btn-red {
        background-color: $red !important;
        color: white !important;
    }

    &.btn-light-purple {
        background-color: $light-purple !important;
        color: white !important;
    }

    &.btn-purple {
        background-color: $purple !important;
        color: white !important;
    }

    &.btn-cyan {
        background-color: $cyan !important;
        color: white !important;
    }

    &.btn-inline-purple {
        color: $purple !important
    }
}

@media screen and (min-width: 768px) {
    .btn {
        padding: 0.5rem 2.25rem;
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1600px) {
    .btn {
        font-size: 1.125rem;
    }
}

.btn-pagination {
    border-radius: 50%;
    border: 2px solid $black;
    height: 2.875rem;
    width: 2.875rem;
    color: $black;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.35rem;
    transition: background-color 175ms ease-out, color 175ms ease-out, border-color 175ms ease-out;
    font-family: $font-family-avenir;
    font-weight: 900;

    i {
        position: relative;
        left: 1px;
    }

    &.btn-pagination-superior {
        &.active, &:hover {
            border-color: $cyan;
            background-color: $cyan;
        }
    }

    &.btn-pagination-previous {
        border-color: #8E8E8E;
        color: #8E8E8E;

        i {
            left: -1px;
        }

        &:first-of-type {
            margin-left: 0;
        }
    }

    &.active {
        background-color: $light-purple;
        border-color: $light-purple;
        color: white;
    }

    &:hover {
        text-decoration: none;
        background-color: $light-purple;
        border-color: $light-purple;
        color: white;

        &.btn-pagination-previous {
            background-color: $red;
            border-color: $red;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.btn-group {

    &.btn-pills-group {
        margin-bottom: 2rem;

        &.btn-group-red {
            button, a.btn {
                border-color: $red !important;
                &.active, &:hover {
                    background-color: $red;
                    color: white;
                }
            }
        }
        &.btn-group-cyan {
            button, a.btn {
                border-color: $cyan !important;
                &.active, &:hover {
                    background-color: $cyan;
                    color: white;
                }
            }
        }
        &.btn-group-yellow {
            button, a.btn {
                border-color: $yellow !important;
                &.active, &:hover {
                    background-color: $yellow;
                    color: white;
                }
            }
        }
        &.btn-group-light-purple {
            button, a.btn {
                border-color: $light-purple !important;
                &.active, &:hover {
                    background-color: $light-purple;
                    color: white;
                }
            }
        }

        button, a.btn {
            transition: color 175ms ease-out, background-color 175ms ease-out;
            border-bottom: 2px solid $red;
            border-top: 2px solid $red;
            background-color: white;
            font-weight: 400;
            color: $black;
            padding: 0.3rem 2.5rem;

            &:hover {
                background-color: $red;
                color: white;
            }

            &:first-of-type {
                border-bottom-left-radius: 50px;
                border-top-left-radius: 50px;
                border-right: 2px solid $red;
                border-left: 2px solid $red;
            }
            &:last-of-type {
                border-bottom-right-radius: 50px;
                border-top-right-radius: 50px;
                border-right: 2px solid $red;
                border-left: 2px solid $red;
            }
        }
    }


}

@media screen and (min-width: 768px) {
    .btn {
        width: auto;
    }
}