.snow-container {
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 5000;
	top: 0;
	left: 0;
	pointer-events: none;

	.snowflake {
		--size: 1vw;
		width: var(--size);
		height: var(--size);
		background: white;
		border-radius: 50%;
		position: absolute;
		top: -5vh;
		pointer-events: none;

		@for $i from 1 through 50 {
			&:nth-child(#{$i}) {
				--size: #{random(5) * 0.2}vw;
				--left-ini: #{random(20) - 10}vw;
				--left-end: #{random(20) - 10}vw;
				left: #{random(100)}vw;
				animation: snowfall #{5 + random(10)}s linear infinite;
				animation-delay: -#{random(10)}s;
			}
		}

		/* added small blur every 6 snowflakes*/
		&:nth-child(6n) {
			filter: blur(1px);
		}
	}
}

@keyframes snowfall {
	0% {
		transform: translate3d(var(--left-ini), 0, 0);
	}
	100% {
		transform: translate3d(var(--left-end), 110vh, 0);
	}
}

