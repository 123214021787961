.branch-map {
	position: relative;

	.search, .branches {
		position: absolute;
		z-index: 9999;
		width: 100%;
	}

	.branches {
		position: static;
	}

	.search {
		top: 30px;

		.form-control {
			height: auto;
		}
	}

	.branches {
		[data-map-location] {
			cursor: pointer;
		}
		span {
			font-family: $font-family-avenir;
			letter-spacing: 0.4px;
			line-height: 3.125rem;
			font-size: 1.25rem;
			text-align: center;
			font-weight: 900;
			display: block;
			color: white;
		}
	}

	div#map {
		min-height: 600px;

		.gm-style-iw {
			span, p {
				font-family: $font-family-avenir;
				font-size: 1rem;

				strong {
					font-weight: bold;
				}

				&.marker-heading {
					font-weight: bold;
					font-size: 1.25rem;
				}
			}
		}

	}
}

@media screen and (min-width: 1024px) {
	.branch-map {
		.branches {
			bottom: 0;
			position: absolute;
		}
	}
}

@media screen and (max-width: 1023px) {
	.branch-map {
		.branches {
			> div {
				padding-left: 0;
				padding-right: 0;

				> div {
					flex-direction: column;
				}

			}
		}
	}

}