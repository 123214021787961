h1, .h1 {
	font-size: 2rem;
}

h2, .h2 {
	font-size: 2rem;
}

h3, .h3 {
	font-size: 1.8rem;
}

h4, .h4 {
	font-size: 2.25rem;
}

h5, .h5 {
	font-size: 1.75rem;
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $font-family-avenir;
	font-weight: 900;
	padding: 0 0 10px 0;
	margin: 0 0 2rem 0;
	letter-spacing: 0.4px;
	z-index: 5001; /* prevents snow falling over headings */
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, span {
	&.underline {
		position: relative;
		display: inline-block;

		&.underline-yellow {
			&::before {
				background-color: $yellow;
			}
		}

		&.underline-light-purple {
			&::before {
				background-color: $light-purple;
			}
		}

		&.underline-purple {
			&::before {
				background-color: $purple;
			}
		}

		&.underline-red {
			&::before {
				background-color: $red;
			}
		}

		&.underline-cyan {
			&::before {
				background-color: $cyan;
			}
		}

		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 60%;
			height: 2px;
			border: none;
			outline: none;
			display: block;
			content: " ";
			background-color: black;
		}
	}
}

a {
	color: $purple;

	&:hover {
		color: $purple;
	}
}

p {
	font-family: $font-family-avenir;
	color: $paragraph-color;
	letter-spacing: 0.4px;
	font-size: 1.125rem;
	line-height: 1.85rem;
	padding: 0;
	margin: 0 0 1.75rem 0;
	z-index: 5001; /* prevents snow falling over headings */

	&.text-bold {
		font-weight: 500;
	}

	&.title {
		font-size: 1.5625rem;
		line-height: 2.3rem;
		font-weight: 900;
	}

	span.heading {
		margin-bottom: -1rem;
		margin-top: 2rem;
	}

	&.font-size-small {
		font-size: 0.9rem;
		line-height: 1.45rem;
	}
}

span.heading {
	font-family: $font-family-avenir;
	letter-spacing: 0.4px;
	font-size: 1.5rem;
	font-weight: 900;
	display: block;
	z-index: 5001; /* prevents snow falling over headings */
}

span.title {
	z-index: 5001; /* prevents snow falling over headings */
}

ul {
	padding-left: 20px;
	z-index: 5001; /* prevents snow falling over headings */

	li {
		font-family: $font-family-avenir;
		font-size: 1.125rem;
	}
}

.text-light-purple {
	color: $light-purple !important;

	&:hover {
		color: $light-purple !important;
	}
}
.text-purple {
	color: $purple !important;

	&:hover {
		color: $purple !important;
	}
}
.text-red {
	color: $red !important;

	&:hover {
		color: $red !important;
	}
}

@media only screen and (min-width: 768px) {
	h1, .h1 {
		font-size: 3.75rem;
	}

	h2, .h2 {
		font-size: 3.25rem;
	}

	h3, .h3 {
		font-size: 2.75rem;
	}

	h4, .h4 {
		font-size: 2.25rem;
	}

	h5, .h5 {
		font-size: 1.75rem;
	}

	p {
		font-size: 1.25rem;
		line-height: 1.85rem;

		&.text-bold {
			font-weight: 900;
		}

		&.title {
			font-size: 1.5625rem;
			line-height: 2.3rem;
			font-weight: 900;
		}

		span.heading {
			margin-bottom: -1rem;
			margin-top: 2rem;
		}
	}
}