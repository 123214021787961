.main-content.make-a-payment {
	.payment-content {
		display: flex;

		div.content {
			order: 2;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 2rem;

			span.title {
				font-size: 1.5rem;
				font-weight: 900;
				margin-bottom: 1.5rem;
			}
		}

		div.image {
			order: 1;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			img {
				display: block;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.main-content.make-a-payment {
		.payment-content {

			div.image img {
				display: none;
			}

			div.content {
				padding: 4rem;
			}

			&:nth-child(even) {
				div.content {
					order: 1;
				}

				div.image {
					order: 2;
				}
			}
		}
	}
}