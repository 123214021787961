.page-banner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 275px;

    img {
        display: none;
    }

    &::after {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
        position: absolute;
        display: block;
        content: " ";
        border: none;
        height: 100%;
        width: 100%;
        z-index: 5;
        bottom: 0;
        left: 0;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;

        h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
            font-family: $font-family-avenir;
            display: inline-block;
            line-height: 3rem;
            font-size: 2.5rem;
            font-weight: 900;
            color: white;
            padding: 0 0 0.75rem 0;
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            //
            //&::after {
            //    background-color: $purple;
            //    position: absolute;
            //    display: block;
            //    content: " ";
            //    border: none;
            //    height: 2px;
            //    width: 73%;
            //    z-index: 5;
            //    bottom: 0;
            //    left: 0;
            //}
            //
            //&.underline-cyan::after {
            //    background-color: $cyan;
            //}
        }
    }
}

@media screen and (min-width: 768px) {
    .page-banner {
        min-height: 450px;

        .content {
            h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
                line-height: 3.75rem;
                font-size: 3rem;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .page-banner {
        min-height: 400px;

        .content {
            h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
                line-height: 3.75rem;
                font-size: 3rem;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .page-banner {
        min-height: 0px;

        img {
            display: block;
            width: 100%;
        }

        .content {
            h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
                line-height: 4.8rem;
                font-size: 3.75rem;
            }
        }
    }
}

