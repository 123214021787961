.card.card-property-room-info {

	&.red ul.room-list li i {color:$red;}
	&.purple ul.room-list li i {color:$purple;}

	ul.room-list {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			align-items: center;
			padding: 0 0 1.5rem 0;
			display: flex;
			margin: 0;
			font-family: $font-family-avenir;
			font-weight: 400;
			font-size: 1.25rem;

			&:last-of-type {
				padding-bottom: 0;
			}

			i {
				font-size: 2rem;
				margin-right: 1rem;
				color: $yellow;
			}
		}
	}
}