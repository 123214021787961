.card {
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
	border-top: 2px solid $yellow;
	border-left: none;
	border-right: none;
	border-bottom: none;
	padding: 1.25rem;
	border-radius: 0;

	&.red {
		border-color: $red;

		.btn-inline {
			i {
				color: $red;
			}
		}
	}

	&.cyan {
		border-color: $cyan;

		.btn-inline {
			i {
				color: $cyan;
			}
		}
	}

	&.yellow {
		border-color: $yellow;

		.btn-inline {
			i {
				color: $yellow;
			}
		}
	}

	&.light-purple {
		border-color: $light-purple;

		.btn-inline {
			i {
				color: $light-purple;
			}
		}
	}

	&.purple {
		border-color: $purple;

		.btn-inline {
			i {
				color: $purple;
			}
		}
	}

	.btn-inline {
		padding-right: 0;
		padding-left: 0;
		text-align: left;

		i {
			position: relative;
			margin-right: 0.5rem;
			font-size: 1.3rem;
			top: 3px;
		}
	}


	span {
		font-family: $font-family-avenir;
		font-size: 1rem;
		padding: 0 0 1rem 0;
		font-weight: 300;
		margin: 0;

		&.title, &.heading {
			font-weight: 900;
			font-size: 1.5625rem;
		}

		strong {
			font-weight: 900;
		}
	}
}

@media screen and (min-width: 1600px) {
	.card {
		padding: 1.5625rem;

		.btn-inline {
			i {
				margin-right: 1rem;
				font-size: 1.5rem;
				top: 3px;
			}
		}


		span {
			font-size: 1.25rem;
			padding: 0 0 1rem 0;
			margin: 0;

			&.title, &.heading {
				font-size: 1.5625rem;
			}
		}
	}
}