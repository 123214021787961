.large-tabbed-content {
	.tabbed-content-area {
		display: none;

		&.active {
			display: block;
		}

		.box {
			border: 3px solid $yellow;
			padding: 1.5rem;

			span.heading {
				margin: 0 0 1.5rem 0;
			}

			&.purple {
				border-color: $purple;
			}
			&.red {
				border-color: $red;
			}
		}

		h5::before {
			background-color: $yellow;
		}
		&:nth-of-type(2n) h5::before {
			background-color: $purple;
		}
	}

	.button {

		&.tabbable {
			> a {
				opacity: 0.5;

				&.active {
					opacity: 1;
				}
			}
		}
		> a {
			transition: opacity 250ms ease-out;
			justify-content: space-evenly;
			border: 3px solid $yellow;
			flex-direction: column;
			word-break: break-word;
			border-radius: 10px;
			align-items: center;
			padding: 0 4rem;
			display: flex;
			height: 100%;
			width: 100%;

			&:hover {
				text-decoration: none;
				opacity: 0.5;
			}

			.circle {
				border: 3px solid $yellow;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				margin: 2rem 0;
				display: flex;
				height: 8rem;
				width: 8rem;

				i {
					color: $yellow;
					font-size: 4rem;
				}
			}

			span {
				font-family: $font-family-avenir;
				font-size: 1.526rem;
				margin: 0 0 2rem 0;
				font-weight: 900;
				color: $yellow;
				padding: 0;
			}
		}

		&:nth-of-type(2n) {
			> a {
				border-color: $purple;

				span {
					color: $purple;
				}

				.circle {
					border-color: $purple;

					i {
						color: $purple;
					}
				}
			}
		}

		&:nth-of-type(3n) {
			> a {
				border-color: $purple;

				span {
					color: $purple;
				}

				.circle {
					border-color: $purple;

					i {
						color: $purple;
					}
				}
			}
		}
	}
}