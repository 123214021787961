#property-search-form {

}

.property-result {
	width: 100%;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
	margin-bottom: 2.5rem;

	&.superior div.property-information-container div.bottom-info span i {
		border-color: $cyan;
		color: $cyan;
	}

	.inner {
		display: block;
		position: relative;

		div.images {
			display: flex;
			flex-wrap: nowrap;

			div.large {
				max-width: 100%;
				flex-basis: 65%;

				img {
					width: 100%;
				}
			}

			div.small {
				flex-basis: 35%;
				margin-left: 5px;
				overflow: hidden;

				div {
					display: flex;
					flex-grow: 1;
					height: 50%;
					width: 100%;
					margin: 5px 0;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;

					&:first-of-type {
						margin-top: 0;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}

		div.name {
			background-color: rgba(255,255,255,0.9);
			padding: 0.25rem 1rem;
			align-content: center;
			position: absolute;
			display: flex;
			bottom: 0;
			left: 0;

			img {
				align-self: center;
				display: flex;
			}

			span {
				font-family: $font-family-avenir;
				position: relative;
				font-size: 1.25rem;
				margin-left: 10px;
				font-weight: 900;
				color: $black;
				top: 4px;
			}
		}

		&:hover {
			text-decoration: none;
		}

	}

	.property-information-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 0 1.875rem;

		span {
			font-family: $font-family-avenir;
			letter-spacing: 0.4px;
			font-size: 1.5625rem;
			line-height: 1.875rem;
			font-weight: 400;
			color: $black;
			display: block;
			//width: 100%;
			padding: 0 0 1rem 0;

			&.price {
				font-weight: 900;

				span.status {
					display: inline-block;
					font-size: 1.25rem;
					font-weight: 900;
					padding: 0;
					margin: 0;
				}
			}
		}

		.bottom-info {
			justify-content: space-between;
			display: flex;

			span {
				line-height: 1rem;
				font-size: 1rem;
				display: flex;
				align-items: center;

				i {
					color: $yellow;
					border-radius: 50%;
					text-align: center;
					padding: 5px;
					font-size: 0.8rem;
					border: 1px solid $yellow;
					margin-right: 10px;
				}
			}
		}

		p {
			font-family: $font-family-avenir;
			letter-spacing: 0.4px;
			font-size: 1.125rem;
			line-height: 1.5625rem;
			font-weight: 400;
			color: $black;
			padding: 0;
			margin: 0;
		}
	}

	&:hover {
		text-decoration: none;
	}
}