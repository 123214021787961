.news-at-boydens {
    .news-item {

        a.inner {
            transition: opacity 150ms ease-out;
            color: $paragraph-color;
            opacity: 1;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
            flex-direction: column;
            display: flex;
            height: 100%;

            .image {
                position: relative;

                img {
                    width: 100%;
                }

                .date {
                    font-family: $font-family-avenir;
                    background-color: $yellow;
                    padding: 0.5rem 1rem;
                    position: absolute;
                    font-weight: 900;
                    font-size: 1rem;
                    color: white;
                    bottom: 0;
                    left: 0;
                }
            }

            .detail {
                justify-content: space-between;
                padding: 1.875rem 1.5rem;
                flex-direction: column;
                display: flex;
                flex-grow: 1;

                span {
                    font-family: $font-family-avenir;
                    display: block;

                    &.title {
                        font-size: 1.5625rem;
                        line-height: 1.7rem;
                        margin-bottom: 1rem;
                        font-weight: 900;
                    }

                    &.author {
                        font-size: 1.125rem;
                        font-weight: 400;
                    }
                }
            }

            &:hover {
                text-decoration: none;
                opacity: 0.75;
            }
        }

        &:nth-child(2) a.inner .image .date {
            background-color: $light-purple;
        }

        &:nth-child(3) a.inner .image .date {
            background-color: $purple;
        }
    }
}

.newsletter-sign-up {
    background-image: url('/images/newsletter-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .content {
        padding: 4.25rem 3.75rem;
        color: white;

        h4 {
            margin: 0 0 1.5rem 0;
            padding: 0;
        }

        p {
            color: white;
            margin: 0 0 2rem 0;
        }

        .form-control {
            height: auto;
            max-width: 400px;
        }
    }
}

.thinking-of-selling-container {
    position: relative;

    .selling-inner {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;

        .overlay {
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
            background-color: rgba(255, 255, 255, 0.90);
            border-top: 2px solid $purple;
            transform: translateY(-50%);
            padding: 2rem 2.25rem;
            position: relative;
            max-width: 475px;
            width: 100%;
            top: 35%;
            left: 0;

            div.icon {
                justify-content: center;
                display: flex;
                width: 100%;

                i {
                    width: 125px;
                    height: 125px;
                    border: 2px solid $yellow;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 3.5rem;
                    margin: 0;
                    padding: 2rem 1.57rem;
                    text-align: center;
                    color: $yellow;
                }
            }

            span {
                font-family: $font-family-avenir;
                font-weight: 900;
                display: block;
                color: black;
                font-size: 2.4rem;
                padding: 0;
                margin: 0 0 1.525rem;
                line-height: 3rem;
                letter-spacing: 0.4px;
            }
        }
    }

    .logo-overlay {
        background-color: rgba(255,255,255,0.90);
        position: absolute;
        padding: 1.9rem;
        bottom: 30px;
        right: 0;

        img {
            max-width: 145px;
            height: auto;
            width: 100%;
        }
    }
}

.superior-home-item, .similar-home-item {
    .inner {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
        transition: opacity 200ms ease-out;
        position: relative;
        display: block;
        color: $black;
        opacity: 1;

        .top-image {
            position: relative;

            div.branch-name {
                background-color: rgba(255, 255, 255, 0.9);
                align-content: center;
                padding: 0.4rem 1rem;
                position: absolute;
                display: flex;
                bottom: 0;
                left: 0;

                img {
                    align-self: center;
                    display: flex;
                    height: 22px;
                    width: 23px;
                }

                span {
                    font-family: $font-family-avenir;
                    position: relative;
                    margin-left: 10px;
                    font-weight: 900;
                    font-size: 1rem;
                    color: $black;
                    top: 3px;
                }
            }
        }

        .bottom-info {
            padding: 1.875rem 1rem;

            .location, .price {
                font-family: $font-family-avenir;
                letter-spacing: 0.4px;
                font-size: 1.25rem;
                display: block;
            }

            .location {
                font-weight: 900;
            }

            .price {
                font-weight: 400;
            }

            .room-info {
                font-size: 1rem;
                display: flex;
                justify-content: space-between;

                span {
                    font-family: $font-family-avenir;
                    letter-spacing: 0.4px;
                    color: $black;

                    i {
                        position: relative;
                        top: -1px;
                        border: 1px solid $cyan;
                        border-radius: 50%;
                        margin-right: 5px;
                        font-size: 0.7rem;
                        padding: 5px;
                        color: $cyan;
                    }
                }
            }
        }

        &:hover {
            text-decoration: none;
            opacity: 0.75;
        }
    }
}