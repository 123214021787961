.top-bar {
    background-color: $purple;

    .navbar {
        padding: 0.5rem 0;

        ul.navbar-nav {
            li.nav-item {
                a.nav-link {
                    font-family: $font-family-avenir;
                    font-size: 1.125rem;
                    font-weight: 900;
                    padding: 0.19rem 1rem;
                    line-height: 1.75rem;
                    color: white;

                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    .top-bar {
        position: static;
    }
}