// Fonts
@import 'fonts/avenir';
@import 'fonts/font-awesome';

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/bootstrap';

// Plugins and Packages
@import '~@glidejs/glide/dist/css/glide.core.css';
@import '~tiny-slider/src/tiny-slider';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';
@import '~bootstrap-select/dist/css/bootstrap-select.css';

// Components
@import 'components/top-bar';
@import 'components/header';
@import 'components/footer';
@import 'components/buttons';
@import 'components/banners';
@import 'components/forms';
@import 'components/typography';
@import 'components/icon-buttons';
@import 'components/classes';
@import 'components/page-banner';
@import 'components/cta-banners';
@import 'components/cards';
@import 'components/page-backgrounds';
@import 'components/accordions';
@import 'components/branch-map';
@import 'components/modal';
@import 'components/value_my_property';
@import 'components/slider-with-content';
@import 'components/our-partners';
@import 'components/share-card';
@import 'components/property/info-card';
@import 'components/property-alerts-dialog';
@import 'components/value-property-dialog';
@import 'components/large-tabbed-content';

//Pages
@import 'pages/news';
@import 'pages/homepage';
@import 'pages/our-branches';
@import 'pages/property-search';
@import 'pages/property-single';
@import 'pages/superior-homes';
@import 'pages/about-us';
@import 'pages/meet-the-team';
@import 'pages/block-management';
@import 'pages/careers';
@import 'pages/make-a-payment';
@import 'pages/life-at-home';

//Common
@import 'common';
@import 'common/_meet-the-team';

//Christmas theme
@import 'components/snowflakes';

html {
	font-size: 16px;
}

//@media screen and (min-width: 1200px) and (max-width: 1440px) {
//    .container-fluid.header-container {
//        padding-right: 0;
//        padding-left: 0;
//        margin-right: -15px;
//        margin-left: -15px;
//    }
//}

.pac-container {
	z-index: 9999999999 !important;
}

.fade-top-white, .fade-bottom-white, .white-strip-fade {
	background-repeat: no-repeat;
	position: relative;

	&::before {
		content: " ";
		display: block;
		width: 100%;
		position: absolute;
		height: 3.5rem;
		left: 0;
	}
}

.white-strip-fade {
	padding-top: 3.5rem;
	background-image: linear-gradient(0deg, transparent, transparent);
	background-position: 0 -3.5rem;

	&::before {
		bottom: 0;
		background-image: linear-gradient(180deg, white, transparent);
	}
}

.fade-top-white {
	padding-top: 4.5rem;
	background-image: linear-gradient(0deg, white, white);
	background-position: 0 3.5rem;

	&::before {
		top: 0;
		background-image: linear-gradient(360deg, white, transparent);
	}
}

.fade-bottom-white {
	padding-bottom: 4.5rem;
	background-image: linear-gradient(0deg, white, white);
	background-position: 0 -3.5rem;

	&::before {
		bottom: 0;
		background-image: linear-gradient(180deg, white, transparent);
	}
}


@media screen and (min-width: 1200px) {
	.container-fluid {
		padding-right: 50px; //130px
		padding-left: 50px; //130px
	}
}

@media screen and (min-width: 1440px) {
	.container-fluid {
		padding-right: 70px;
		padding-left: 70px;
	}
}

@media screen and (min-width: 1600px) {
	.container-fluid {
		padding-left: 160px;
		padding-right: 160px;
	}
}

@media screen and (min-width: 1920px) {
	.container-fluid {
		padding-left: 180px;
		padding-right: 180px;
	}
}

@media screen and (min-width: 2100px) {
	.container-fluid {
		padding-left: 280px;
		padding-right: 280px;
	}
}

@media screen and (-Webkit-min-device-pixel-ratio: 1.01) and (min-width: 1800px) and (max-width: 1920px),
screen and (-moz-min-device-pixel-ratio: 1.01) and (min-width: 1800px) and (max-width: 1920px),
screen and (-o-min-device-pixel-ratio: 3/2) and (min-width: 1800px) and (max-width: 1920px),
screen and (min-device-pixel-ratio: 1.01) and (min-width: 1800px) and (max-width: 1920px) {

	html {
		font-size: 13px;
	}
}
