.about-us {
	.service-item {

		margin-bottom: 30px;

		&:nth-child(5n+2) .inner {
			.title { background-color: $purple; }
			.overlay .btn { background-color: $purple !important; }
		}

		&:nth-child(5n+3) .inner {
			.title { background-color: $cyan }
			.overlay .btn { background-color: $cyan !important; }
		}

		&:nth-child(5n+4) .inner {
			.title { background-color: $yellow }
			.overlay .btn { background-color: $yellow !important; }
		}

		&:nth-child(5n+5) .inner {
			.title { background-color: $red }
			.overlay .btn { background-color: $red !important; }
		}

		&:nth-child(5n+6) .inner {
			.title { background-color: $light-purple }
			.overlay .btn { background-color: $light-purple !important; }
		}

		&:hover .inner {
			.overlay {
				opacity: 1;
			}
		}

		.inner {
			position: relative;

			.title {
				font-family: $font-family-avenir;
				letter-spacing: 0.4px;
				background-color: $red;
				line-height: 50px;
				position: absolute;
				font-size: 35px;
				padding: 7px 20px;
				font-weight: 900;
				display: block;
				color: white;
				z-index: 50;
				left: 0;
				top: 20px;
			}

			.btn {
				background-color: $red;
			}

			.overlay {
				background-color: rgba(255,255,255,0.9);
				transition: opacity 250ms ease-out;
				position: absolute;
				color: $black;
				opacity: 0;
				top: 84px;
				left: 0;
				padding: 20px;

				p {
					font-family: $font-family-avenir;
					letter-spacing: 0.4px;
					line-height: 1.5625rem;
					font-size: 1.125rem;
					padding-bottom: 0.6rem;
					margin-bottom: 0;

					&:last-of-type {
						margin-bottom: 0;
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.history-item {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);

		.content {
			justify-content: center;
			flex-direction: column;
			display: flex;

			p {
				font-size: 1.125rem;
				line-height: 1.562rem;
				margin-bottom: 0.75rem;

				&:last-of-type {
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}

			span, p {
				font-family: $font-family-avenir;
				letter-spacing: 0.4px;
				color: $black;
			}

			span.title {
				font-size: 1.5625rem;
				margin-bottom: 0.8rem;
				font-weight: 900;
				display: block;
			}
		}
	}
}