.superior-homes {
	.superior-overlay {
		position: relative;
		margin-bottom: 30px;

		&.yellow .content i {
			color: $yellow;

			&::after {
				border-color: $yellow;
			}
		}

		&.cyan .content i {
			color: $cyan;

			&::after {
				border-color: $cyan;
			}
		}

		&.purple .content i {
			color: $purple;

			&::after {
				border-color: $purple;
			}
		}

		&.light-purple .content i {
			color: $light-purple;

			&::after {
				border-color: $light-purple;
			}
		}

		&.red .content i {
			color: $red;

			&::after {
				border-color: $red;
			}
		}

		.content {
			background-color: rgba(#FFFFFF, 0.9);
			font-family: $font-family-avenir;
			padding: 1rem 3rem 1rem 2rem;
			line-height: 1.5625rem;
			letter-spacing: 0.4px;
			font-size: 1.12rem;
			position: absolute;
			font-weight: 400;
			display: flex;
			color: $black;
			bottom: 30px;
			width: 100%;
			left: 0;

			i {
				position: relative;
				font-size: 22px;
				align-self: center;
				margin: 0 2rem 0 0;
				color: $cyan;
				z-index: 5;

				&::after {
					content: " ";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 43px;
					width: 43px;
					background-color: white;
					border-radius: 50%;
					border: 2px solid $cyan;
					z-index: -1;
				}
			}
		}
	}
}


//@media screen and (min-width: 1440px) {
//	.superior-homes {
//		.meet-the-team-container {
//			.team-members {
//				.team-member {
//					.inner {
//						padding: 1.875rem 0 1rem 0;
//					}
//				}
//			}
//		}
//	}
//}