.meet-the-team-container {
	.team-members {
		.team-member {
			.inner {
				padding: 1.875rem 1.5rem 1rem 1.5rem;
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
				text-align: center;

				img {
					border-radius: 50%;
					max-width: 250px;
					width: 100%;
				}

				span {
					font-family: $font-family-avenir;
					line-height: 1.875rem;
					letter-spacing: 0.4px;
					font-size: 1.25rem;
					font-weight: 400;
					display: block;
					color: $black;

					&.name {
						font-weight: 900;
						margin-top: 1.875rem;
					}

					&.position {
						margin-bottom: 1.875rem;
					}
				}

				hr {
					width: 80%;
				}
			}

		}
	}
}


@media screen and (min-width: 1200px) {
	.meet-the-team-container {
		.team-members {
			.team-member {
				.inner {
					padding: 1.875rem 0.75rem 1rem 0.75rem;
				}
			}
		}
	}
}