.our-partners-component {
	position: relative;
	overflow: hidden;

	.bg-image {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		left:0;
	}

	.partners-image {
		width: auto;
		max-width: 100%;
		height: auto;
		max-height: 350px;
		display: flex;
		align-self: center;
	}

	.content-container {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
		background-color: white;
		padding: 3rem;

		div.content {
			p {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}