ul.share-list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: inline-block;
		margin: 0 12px 0 0;

		&.spacer {
			width: 25px;
		}

		&.link {
			position: relative;
			top: -12px;

			a {
				width: 2.625rem;
				height: 2.5rem;

				.fa-square {
					color: $link;
				}

				.fa-link {
					font-size: 1.5rem;
					color: white;
				}
			}
		}

		a {
			-webkit-transition: opacity 150ms ease-out;
			-moz-transition: opacity 150ms ease-out;
			-ms-transition: opacity 150ms ease-out;
			-o-transition: opacity 150ms ease-out;
			transition: opacity 150ms ease-out;

			line-height: 2.5rem;
			font-size: 2.5rem;
			cursor: pointer;
			opacity: 1;

			&:hover {
				background-color: inherit;
				text-decoration: none;
				color: inherit;
				opacity: 0.5;
			}

			i {
				&.fa-pinterest-square {
					color: $pinterest;

					&:hover {
						color: $pinterest;
					}
				}

				&.fa-facebook-square {
					color: $facebook;

					&:hover {
						color: $facebook;
					}
				}

				&.fa-whatsapp-square {
					color: $whatsapp;

					&:hover {
						color: $whatsapp;
					}
				}

				&.fa-twitter-square {
					color: $twitter;

					&:hover {
						color: $twitter;
					}
				}

				&.fa-linkedin {
					color: $linkedin;

					&:hover {
						color: $linkedin;
					}
				}

				&.fa-envelope-square {
					color: $mail;

					&:hover {
						color: $mail;
					}
				}
			}
		}
	}
}