@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/avenir/Avenir-MediumOblique.eot');
    src: local('Avenir Medium Oblique'), local('Avenir-MediumOblique'),
    url('../../fonts/avenir/Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/Avenir-MediumOblique.woff2') format('woff2'),
    url('../../fonts/avenir/Avenir-MediumOblique.woff') format('woff'),
    url('../../fonts/avenir/Avenir-MediumOblique.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/avenir/Avenir-Oblique.eot');
    src: local('Avenir Oblique'), local('Avenir-Oblique'),
    url('../../fonts/avenir/Avenir-Oblique.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/Avenir-Oblique.woff2') format('woff2'),
    url('../../fonts/avenir/Avenir-Oblique.woff') format('woff'),
    url('../../fonts/avenir/Avenir-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/avenir/Avenir-Medium.eot');
    src: local('Avenir Medium'), local('Avenir-Medium'),
    url('../../fonts/avenir/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/Avenir-Medium.woff2') format('woff2'),
    url('../../fonts/avenir/Avenir-Medium.woff') format('woff'),
    url('../../fonts/avenir/Avenir-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/avenir/Avenir-Black.eot');
    src: local('Avenir Black'), local('Avenir-Black'),
    url('../../fonts/avenir/Avenir-Black.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/Avenir-Black.woff2') format('woff2'),
    url('../../fonts/avenir/Avenir-Black.woff') format('woff'),
    url('../../fonts/avenir/Avenir-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/avenir/Avenir-Light.eot');
    src: local('Avenir Light'), local('Avenir-Light'),
    url('../../fonts/avenir/Avenir-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/Avenir-Light.woff2') format('woff2'),
    url('../../fonts/avenir/Avenir-Light.woff') format('woff'),
    url('../../fonts/avenir/Avenir-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../../fonts/avenir/Avenir-Roman.eot');
    src: local('Avenir Roman'), local('Avenir-Roman'),
    url('../../fonts/avenir/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/avenir/Avenir-Roman.woff2') format('woff2'),
    url('../../fonts/avenir/Avenir-Roman.woff') format('woff'),
    url('../../fonts/avenir/Avenir-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

