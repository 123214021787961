#valuePropertyModal {
	.modal__content {
		height: 100%;
		max-height: 80%;

		iframe {
			height: 75vh;
			display:block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}