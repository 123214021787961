.video-banner {
	overflow: hidden;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%);

	.content {
		padding: 40px 10px;
	}
}

.video-banner, .image-banner {
	position: relative;

	.content {

		span {
			color: white;
			font-family: $font-family-avenir;
			font-size: 1.7rem;
			display: block;

			&.sub-heading {
				font-size: 1.5rem;
			}
		}

		.search {
			.form-control {
				//max-width: 600px;
				height: auto;
			}

			.btn-pills {
				margin: 1.5rem 0;

				&.btn-group-toggle-yellow label.btn {
					border-color: $yellow;

					&.active {
						background-color: $yellow;
						color: white;
					}
				}

				&.btn-group-toggle-red label.btn {
					border-color: $red;

					&.active {
						background-color: $red;
						color: white;
					}
				}

				&.btn-group-toggle-light-purple label.btn {
					border-color: $light-purple;

					&.active {
						background-color: $light-purple;
						color: white;
					}
				}

				label.btn {
					font-family: $font-family-avenir;
					border: 2px solid $yellow;
					background-color: white;
					letter-spacing: 0.4px;
					font-size: 1rem;
					font-weight: 400;
					color: $black;
					padding: 0.5rem 1rem;

					@media screen and (min-width: 380px) {
						font-size: 1.125rem;
						padding: 0.5rem 1.4rem;
					}

					&.active {
						background-color: $yellow;
						color: white;
					}

					&:hover, &:active, &:focus {
						outline: none;
						box-shadow: none;
					}

					&:first-of-type {
						border-top-left-radius: 50px;
						border-bottom-left-radius: 50px;
					}

					&:last-of-type {
						border-top-right-radius: 50px;
						border-bottom-right-radius: 50px;
					}

					border-radius: 0px;
				}
			}
		}
	}

	.image-background {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom center;
		min-height: 600px;

		img {
			visibility: hidden;
		}

		~ div.container-fluid {
			top: 45%;
			position: absolute;
			left: 0;
			transform: translateY(-50%);
		}
	}

	.video-background {
		position: absolute;
		width: 200%;
		float: none;
		clear: both;
		z-index: -1;
		height: 200%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: white;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

@media screen and (min-width: 576px) {
	.video-banner .video-background {
		left: -50%;
		height: 150%;
	}
}

@media only screen and (min-width: 768px) {
	.video-banner .content {
		padding: 275px 0;
	}

	.video-banner, .image-banner {
		.video-background {
			left: 0;
		}

		.content {
			span {
				font-size: 2rem;
				font-weight: 900;
			}
		}
	}
}

@media only screen and (min-width: 1024px) {
	.video-banner .content {
		padding: 125px 0;
	}

	.video-banner, .image-banner {
		.image-background {
			min-height: 800px;

			~ div.container-fluid {
				top: 45%;
			}
		}

		.content {

			span {
				font-size: 3.75rem;

				&.sub-heading {
					font-size: 1.875rem;
				}
			}

			.search {
				.btn-pills {
					margin: 3.5rem 0 2rem;
				}
			}
		}

		.video-background {
			padding-bottom: 56.2%;
			width: 100%;
			height: 0;
		}
	}
}

@media screen and (min-width: 1440px) {
	.video-banner .content {
		padding: 225px 0;
	}
	.video-banner, .image-banner {
		.image-background {
			min-height: 0;

			img {
				visibility: visible;
			}
		}

	}
}

@media screen and (min-width: 1600px) {
	.video-banner {
		.content {
			padding: 275px 0;
		}
	}
}