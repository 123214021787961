.slider-with-content-component {
	padding-bottom: 3rem;
	&:last-of-type {
		padding-bottom: 0;
	}
	div.content-container {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1rem 3rem;

		div.content {
			p {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}