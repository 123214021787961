.value-my-property-container {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	.content {
		position: static;
		padding: 5rem 0;
		width: 100%;
		left: 0;
		top: 0;

		h3 {
			margin-bottom: 4rem;
		}

		.value-form {
			background-color: white;
			padding: 3.125rem;

			span.heading {
				font-family: $font-family-avenir;
				margin-bottom: 1rem;
				font-size: 1.25rem;
				font-weight: 900;
				color: $black;
				display: block;
			}

			#additional_comments {
				resize: none;
				height: 120px;
			}
		}
	}
}


@media screen and (min-width: 1200px) {

}