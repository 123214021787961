// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Avenir', sans-serif;
$font-family-avenir: 'Avenir', sans-serif;

$font-size-base: 0.9rem;
$line-height-base: 1.6;

$paragraph-color: #1F1F1F;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$pink: #f66d9b;
$orange: #f6993f;
$green: #38c172;
$teal: #4dc0b5;

//Boydens Colours
$light-purple: #7A68AE;
$pink: #8d438d;
$purple: #711471;
$red: #A61318;
$yellow: #efac1f;
$black: #1F1F1F;
$cyan: #00ADDA;
$light-grey: #f3f3f3;

//Social Colours & Share Colours
$facebook: #3B5998;
$twitter: #00ACEE;
$linkedin: #003377;
$whatsapp: #25D366;
$pinterest: #BD081C;
$mail: #0072C6;
$link: #B6B6B6;

