.icon-button {
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
	justify-content: center;
	text-align: center;
	flex-wrap: wrap;
	display: flex;
	height: auto;
	width: 100%;

	a {
		width: 100%;
		transition: opacity 150ms ease-out;

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}
	}

	&.yellow {
		border-color: $yellow;

		div.icon i {
			border-color: $yellow;
			color: $yellow;
		}
	}

	&.light-purple {
		border-color: $light-purple;

		div.icon i {
			border-color: $light-purple;
			color: $light-purple;
		}
	}

	&.purple {
		border-color: $purple;

		div.icon i {
			border-color: $purple;
			color: $purple;
		}
	}

	&.red {
		border-color: $red;

		div.icon i {
			border-color: $red;
			color: $red;
		}
	}

	&.cyan {
		border-color: $cyan;

		div.icon i {
			border-color: $cyan;
			color: $cyan;
		}
	}

	border-top: 2px solid black;

	&.thin {
		div.icon {
            i {
                margin: 1.5rem 0 1rem 0;
                padding: 1.5rem 1.2rem;
				height: 110px;
				width: 110px;
            }
		}

        span {
            margin-bottom: 1rem;
        }
	}

	div.icon {
		width: 100%;

		i {
			width: 125px;
			height: 125px;
			border: 2px solid black;
			justify-content: center;
			border-radius: 50%;
			font-size: 3.5rem;
			margin: 2.5rem 0 1.5rem 0;
			padding: 2rem 1.57rem;
		}
	}

	span {
		color: $paragraph-color;
		margin-bottom: 2rem;
		display: block;
		font-family: $font-family-avenir;
		font-weight: 900;
		font-size: 1.25rem;
		letter-spacing: 0.4px;
	}
}
