.accordion-container {
	.accordion-item {
		background-color: white;
		padding-left: 65px;
		position: relative;
		cursor: pointer;
		height: 80px;
		transition: height 300ms ease-out;
		overflow:hidden;

		div.inner {
			* {
				opacity: 0;
				transition: opacity 500ms ease-out;
			}

			span.heading {
				opacity: 1;
			}
		}

		&[data-collapsed="false"] {
			height: auto;

			div.inner {
				* {
					opacity: 1;
				}
			}
		}

		&:nth-child(4n+2) span.number {
			border-color: $purple;
			color: $purple;
		}

		&:nth-child(4n+3) span.number {
			border-color: $yellow;
			color: $yellow;
		}

		&:nth-child(4n+4) span.number {
			border-color: $light-purple;
			color: $light-purple;
		}

		&:last-of-type {
			border-left: none;
			span.line {
				display: none;
			}
		}

		span.line {
			display: block;
			content: " ";
			position: absolute;
			top: 0;
			left: 22px;
			z-index: 1;
			border-left: 2px dotted #707070;
			height: 100%;
		}

		span.number {
			font-family: $font-family-avenir;
			background-color: white;
			justify-content: center;
			border: 2px solid $red;
			align-items: center;
			font-size: 1.25rem;
			border-radius: 50%;
			position: absolute;
			line-height: 45px;
			font-weight: 900;
			display: flex;
			height: 45px;
			width: 45px;
			color: $red;
			left: 0;
			top: 0;
			z-index: 2;
		}

		div.inner {
			font-family: $font-family-avenir;
			padding-bottom: 3.25rem;
			letter-spacing: 0.4px;
			font-size: 1.125rem;
			padding-top: 5px;
			color: $black;

			span.heading {
				font-size: 1.5625rem;
				margin-bottom: 10px;
				font-weight: 900;
				display: block;
				color: $black;
			}

			:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
			}

			ul {
				padding-left: 20px;

				li {
				}
			}
		}
	}
}