.cta-banner {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding: 0.5rem;

	&.wide {
		.overlay {
			max-width: 600px;
		}
	}

	&.large .overlay {
		max-width: 730px;
		padding: 2rem;
	}

	.overlay {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
		background-color: rgba(255,255,255,0.90);
		border-top: 2px solid $yellow;
		position: static;
		max-width: 475px;
		padding: 1.5rem;
		width: 100%;
		top: 0;
		left: 0;

		&.border-color-purple { border-color: $purple; }
		&.border-color-cyan { border-color: $cyan; }
		&.border-color-yellow { border-color: $yellow; }
		&.border-color-red { border-color: $red; }
		&.border-color-light-purple { border-color: $light-purple; }

		span.heading {
			text-align: left;
			line-height: 2.5rem;
			font-size: 1.85rem;
			padding: 0 0 2rem;

			&.large {
				font-size: 3.3rem;
				span {
					font-size: 3.3rem;
				}
			}

			&.light {
				font-weight: 100;
			}

			span {
				text-align: left;
				padding-top: 0;
			}
		}

		div.icon {
			justify-content: center;
			display: flex;
			width: 100%;

			i {
				width: 125px;
				height: 125px;
				border: 2px solid $yellow;
				justify-content: center;
				border-radius: 50%;
				font-size: 3.5rem;
				margin: 0;
				padding: 2rem 1.57rem;
				text-align: center;
				color: $yellow;
			}
		}

		span {
			font-family: $font-family-avenir;
			font-weight: 900;
			display: block;
			color: black;
			text-align: center;
			font-size: 2.4rem;
			padding: 1.5rem 0 0;
			margin: 0;
			line-height: 3rem;
			letter-spacing: 0.4px;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	.logo-overlay {
		background-color: rgba(255,255,255,0.90);
		position: absolute;
		padding: 1.9rem;
		bottom: 30px;
		right: 0;
		display: none;

		img {
			max-width: 145px;
			height: auto;
			width: 100%;
		}
	}
}

@media screen and (min-width: 768px) {
	.cta-banner {
		padding: 1.5rem;
	}
}

@media screen and (min-width: 1024px) {
	.cta-banner {
		padding: 2.5rem;
	}
}

@media screen and (min-width: 1200px) {


	.cta-banner {
		padding: 4rem 0;
		min-height: 400px;

		.logo-overlay {
			display: block;
		}

		&.large .overlay {
			max-width: 730px;
			padding: 3rem 3rem 3rem 2.5rem;
		}

		//img {
		//	width: 100%;
		//	height: auto;
		//	display: block;
		//}

		.overlay {
			//position: absolute;
			//transform: translateY(-50%);
			//top: 50%;

			span.heading {
				line-height: 3.5rem;
				font-size: 2.4rem;
				padding: 0 0 2rem;

				&.large {
					font-size: 3.3rem;
					span {
						font-size: 3.3rem;
					}
				}
			}

			span {
				font-size: 2rem;
				padding: 1.5rem 0 0;
				margin: 0;
				line-height: 2.875rem;
			}
		}
	}
}